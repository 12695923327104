import { Component, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { LottieComponent, AnimationOptions } from 'ngx-lottie';

@Component({
    selector: 'app-animation-intro',
    templateUrl: './animation-intro.component.html',
    styleUrls: ['./animation-intro.component.scss'],
    standalone: true,
    imports: [LottieComponent]
})
export class AnimationIntroComponent implements OnInit {

  options: AnimationOptions = {
    path: '/assets/images/CWS_Anim.json',
  };

  constructor() { }

  ngOnInit(): void {
  }

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }
}
