<section class="wow fadeInUp" data-wow-delay="0.2s" id="footer">
  <div class="container-fluid base-color text-white">
    <div class="container">
      <div class="row">
        <div class="col-sm-6 col-md-3 col-lg-2">
          <div class="media-wrap">
            <div class="m-4 logo">
              <img src="/assets/images/cws-logo.svg" class="img-fluid" alt="Custom Web Solutions Ltd">
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-2">
          <h4 class="pt-2">
            Address
          </h4>
          <p class="mbr-text">
            Custom Web Solutions Ltd<br />34 Veryan&nbsp;<br />Fareham<br />Hants<br />PO14
            1NN
          </p>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4">
          <h4 class="pt-2">
            Contacts
          </h4>
          <p class="mbr-text">
            <i class="fas fa-envelope"></i>&nbsp;&nbsp;<a href="mailto:andrew@customwebsolutions.ltd">andrew&#64;customwebsolutions.ltd</a><br />
            <i class="fas fa-phone"></i>&nbsp;&nbsp;Phone: Andrew:
            <a href="tel:07736610970">077366 10970</a>
          </p>
          <p class="mbr-text">
            <i class="fas fa-envelope"></i>&nbsp;&nbsp;<a class=""
              href="mailto:charley@customwebsolutions.ltd">charley&#64;customwebsolutions.ltd</a><br />
            <i class="fas fa-phone"></i>                                               &nbsp;&nbsp;Phone: Charley:
            <a href="tel:07772 925024">07772 925024</a>
          </p>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-2">
          <h4 class="pt-2">Links</h4>
          <p class="mbr-text">
            <i class="fab fa-facebook"></i>&nbsp;&nbsp;<a class=""
              href="https://www.facebook.com/CustomWebSolutionsLtd">facebook</a><br />
          </p>
          <p class="mbr-text">
            <i class="fab fa-twitter"></i>&nbsp;&nbsp;<a class=""
              href="https://twitter.com/CWSLimited">twitter</a><br />
          </p>
          <p class="mbr-text">
            <i class="fab fa-instagram"></i>&nbsp;&nbsp;<a class=""
              href="https://www.instagram.com/customwebsolutionsltd/">instagram</a><br />
          </p>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-2">
          <h4 class="pt-2">Policies</h4>
          <p>
            <i class="fas fa-ban"></i>&nbsp;&nbsp;
            <a [routerLink]="'privacy'">Privacy</a><br />
          </p>
          <!-- <p>
            <i class="fab fa-twitter"></i>&nbsp;&nbsp;
            <a [routerLink]="'gdpr'">GDPR</a><br />
          </p> -->
          <p>
            <i class="fas fa-file-signature"></i>&nbsp;&nbsp;
            <a [routerLink]="'terms'">Terms & Conditions</a><br />
          </p>

          <h4 class="pt-2">Sitemap</h4>
          <p>
            <i class="fas fa-sitemap"></i>&nbsp;&nbsp;
            <a [routerLink]="'sitemap'">Sitemap</a><br />
          </p>

        </div>



      </div>
      <div class="footer-lower">
        <div class="media-container-row">
          <div class="col-sm-12">
            <hr />
          </div>
        </div>
        <div class="media-container-row mbr-white pb-3">
          <div class="col-sm-6 copyright">
            <p class="mbr-text">
              © Copyright {{ currentYear }} Custom Web Solutions Ltd - All
              Rights Reserved
            </p>
          </div>
          <div class="col-md-6"></div>
        </div>
      </div>
    </div>
  </div>
</section>
