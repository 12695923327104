import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-tag-link',
    templateUrl: './tag-link.component.html',
    styleUrls: ['./tag-link.component.scss'],
    standalone: true,
    imports: [NgClass]
})
export class TagLinkComponent implements OnInit {

  isActive = false;

  @Input() tag: string;

  @Output() tagPicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  tagClicked(tag: string) {
    this.isActive = !this.isActive;
    this.tagPicked.emit(tag);
  }

}
