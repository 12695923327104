<div class="fluid-container text-center p-3">
  <div class="p-4 base-color">
    <h1 class="cws-blue">Frequently Asked Questions</h1>
    <label class="p-2 text-white">Search our FAQ's:</label>
    <input type="text" [(ngModel)]="search" >
  </div>
  <div>

  </div>
  @for (category of faqs; track category) {
    <div class="faq-category">
      <div class="row">
        <div class="col-2">
          <div class="text-center cws-blue">
            <h3>{{category.category}}</h3>
            <div class="m-2">
              <i [class]="category.icon"></i>
            </div>
          </div>
        </div>
        <div class="col-10">
          @for (faq of category.faqItems | FilterSearchFieldsPipe:search:['question','answer','additional']; track faq) {
            <div>
              <div class="faq-item">
                <app-faq-item [faqItem]="faq"></app-faq-item>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  }

</div>
