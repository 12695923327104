import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-payoptions-content',
    templateUrl: './payoptions-content.component.html',
    styleUrls: ['./payoptions-content.component.scss'],
    standalone: true
})
export class PayoptionsContentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
