import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'FilterNamePipe',
    pure: false,
    standalone: true
})
export class FilterNamePipe implements PipeTransform {
  transform(items: any[], filter: string, field: string): any {
    if (!items || !filter) {
      return items;
    }
    return items.filter(
      item => item[field].toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }
}

@Pipe({
    name: 'FilterSearchFieldsPipe',
    pure: false,
    standalone: true
})
export class FilterSearchFieldsPipe implements PipeTransform {
  transform(items: any[], filter: string, fields: string[]): any {

    if (!items || !filter) {
      return items;
    }

    return items.filter(
      item => ConCat(item, fields).toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );

    function ConCat(item: any, fields: string[]) {
      let search = '';

      fields.forEach(a => {
        if (a.indexOf('.') === -1) {
          search = search + item[a];
        } else {
          const dotsplit = a.split('.');
        //  console.log(dotsplit);
          search = search + item[dotsplit[0]][dotsplit[1]];
        }
      });

      return search;
    }
  }
}
