import { Component, OnInit } from '@angular/core';
import { BlogService } from 'src/app/services/blog.service';
import { Post } from 'src/app/models/post';
import { BlogPostComponent } from '../../components/blogs/blog-post/blog-post.component';

import { TagPickerComponent } from '../../components/tag-picker/tag-picker.component';
import { CategoryPickerComponent } from '../../components/category-picker/category-picker.component';
import { BlogHeaderComponent } from '../../components/blogs/blog-header/blog-header.component';

@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss'],
    standalone: true,
    imports: [BlogHeaderComponent, CategoryPickerComponent, TagPickerComponent, BlogPostComponent]
})
export class BlogComponent implements OnInit {

  posts: Post[];

  currentPosition = 0;
  take = 10;
  categories: string[] = [];
  tags: string[] = [];

  constructor(private blogService: BlogService) {
    this.loadPosts();
  }

  private loadPosts() {
    this.posts = this.blogService.GetPosts(this.categories, this.tags, this.take, this.currentPosition);
  }

  ngOnInit(): void {
  }

  categoryPicked(categories: string[]) {
    this.categories = categories;
    this.loadPosts();
  }

  tagPicked(tags: string[]) {
    this.tags = tags;
    this.loadPosts();
  }
}
