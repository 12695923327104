<div class="navbar-dark base-color p-5">
  <div class="container">
    <div class="text-center text-white">
      <h2>{{title}}</h2>
      <p>{{message}}</p>
    </div>
    <div class="row">
      @for (team of teams; track team) {
        <div [class]="class">
          <div class="p-4">
            <app-team (cardButtonClicked)="cardClicked(team.buttonRoute)"
              [name]="team.name" [position]="team.position"
              [src]="team.src" [cardTitle]="team.cardTitle"
              [cardText]="team.cardText" [buttonText]="team.buttonText" [paddingCSSclass]="'p-5'" [delay]="GetDelay()">
            </app-team>
          </div>
        </div>
      }
    </div>

  </div>
</div>
