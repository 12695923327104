<div class="navbar-dark base-color">
  <div class="container">
    <div class="p-5">
      <div class="text-center text-white p-2">
        <h3>{{title}}</h3>
        <p>{{message}}</p>
      </div>

      <div class="row text-center p-2">
        <div class="col-sm-12 col-md-6 pb-2">
          <a [class]="btnClass" [href]="bthHref">{{contact}}</a>
        </div>
        @if (btnShow2) {
          <div class="col-sm-12 col-md-6">
            <a [class]="btnClass2" [href]="bthHref2">{{contact2}}</a>
          </div>
        }
      </div>

    </div>
  </div>
</div>

