import { Component, OnInit, Input } from '@angular/core';
import { FaqItem } from 'src/app/models/faq';
import { trigger, state, style, transition, animate } from '@angular/animations';


@Component({
    selector: 'app-faq-item',
    templateUrl: './faq-item.component.html',
    styleUrls: ['./faq-item.component.scss'],
    animations: [
        trigger('showFAQ', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('1s', style({ opacity: 1 })),
            ]),
            transition(':leave', [
                animate('.5s', style({ opacity: 0 }))
            ])
        ])
    ],
    standalone: true,
    imports: []
})

export class FaqItemComponent implements OnInit {

  @Input() faqItem: FaqItem;

  panelOpenState = false;

  constructor() { }

  ngOnInit(): void {
  }

}
