import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { HomeComponent } from '../pages/home/home.component';
import { TermsComponent } from '../pages/terms/terms.component';
import { PrivacyComponent } from '../pages/privacy/privacy.component';
import { GdprComponent } from '../pages/gdpr/gdpr.component';
import { BlogComponent } from '../pages/blog/blog.component';
import { BlogFullPostComponent } from '../pages/blog-full-post/blog-full-post.component';
import { FaqComponent } from '../pages/faq/faq.component';
import { PricingComponent } from '../pages/pricing/pricing.component';
import { SitemapComponent } from '../pages/sitemap/sitemap.component';
import { SeoComponent } from '../pages/seo/seo.component';

const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64],
    onSameUrlNavigation: 'reload',
    enableTracing: true
};

const routes: Routes = [
  {path: '', component: HomeComponent },
  {path: 'home', component: HomeComponent },
  {path: 'sitemap', component: SitemapComponent },
  {path: 'terms', component: TermsComponent },
  {path: 'privacy', component: PrivacyComponent },
  {path: 'gdpr', component: GdprComponent },
  {path: 'faq', component: FaqComponent },
  {path: 'blog', component: BlogComponent },
  {path: 'seo', component: SeoComponent },
  {path: 'pricing', component: PricingComponent },
  {path: 'blog/:blogId', component: BlogFullPostComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
