<div class="row">
  <div class="col-12">
      <div class="text-white text-center">
        <h2 class="mt-5 pb-3">Let's do things differently!</h2>
        <h3 class="pb-3">Keep your cashflow under control and just pay for everything monthly</h3>
        <p class="align-left"><strong>Monthly payments will include the Website/App, Updates, Hosting, Emails and much more.</strong>
        </p>
        <div class="mbr-section-btn align-left py-4">
            <a class="btn btn-color-dark"  [routerLink]='["."]' [fragment]="'CashflowWorkflow'">LEARN MORE</a>
        </div>
        <p class="text-white bold"><em>* a small deposit is payable to kick the development phase off</em></p>

      </div>


  </div>
</div>


