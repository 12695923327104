<span class="cat-title">Categories@if (categoriesPicked.length > 0) {
  <span  class="picked"> - includes@for (picked of categoriesPicked; track picked) {
    <span> - {{picked}} </span>
  }</span>
}</span>
<ul class="nav nav-fill justify-content-center">
  @for (cat of categories; track cat) {
    <li class="nav-item cws-blue p-3 text-uppercase">
      <app-category-link [cat]="cat"  (categoryPicked)="categoryClicked($event)"></app-category-link>
    </li>
  }
</ul>
