<app-banner-parallax [bgImage]="'coding.jpg'">
  <app-payoptions-content></app-payoptions-content>
</app-banner-parallax>


<div class="container">

  <app-timeline-workflow id="CashflowWorkflow"></app-timeline-workflow>

</div>

<div class="container-fluid">

  <div class="backgroundMed">

    <div class="container">

      <div class="row">

        <div class="col text-center text-white p-3">
          <h1>Pricing Guidelines</h1>
          <p>(These prices are only a guideline and will always depend on what you require. It's not an exhaustive list but good enough to get to the right ballpark, discounts for larger websites could be available please don't be affraid to ask)</p>
        </div>

      </div>

      <div class="row pb-5">

        <div class="bk-white col-xs-12 col-sm-12 rounded z-depth-2">
          <table class="table table-striped">
            <thead>
              <tr>
                <th></th>
                <th>Traditional</th>
                <th>Cashflow Workflow<br>per month</th>
              </tr>

            </thead>
            <tbody>
              @for (price of prices; track price) {
                <tr>
                  <td><span>{{price.item}} <app-info-panel [price]="price"></app-info-panel></span></td>
                  <td class="col-width">£{{price.traditional}}</td>
                  <td class="col-width">£{{price.cashflow}}</td>
                </tr>
              }
            </tbody>
          </table>
        </div>

      </div>

      <div class="row pb-5">

        <div class="bk-white col-xs-12 col-sm-12 rounded z-depth-2">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Hosting fees - monthly - required for either Traditional & Cashflow Workflow pathways</th>
                <th class="col-width">per month</th>
              </tr>
            </thead>

            <tbody>
              @for (price of monthlies; track price) {
                <tr>
                  <td>{{price.item}} <app-info-panel [price]="price"></app-info-panel></td>
                  <td class="col-width">£{{price.cashflow}}</td>
                </tr>
              }
            </tbody>
          </table>
        </div>

      </div>

      <div class="row pb-5">

        <div class="bk-white col-xs-12 col-sm-12 rounded z-depth-2">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Additional Hourly/Daily rates if required</th>
                <th class="col-width">per hour</th>
                <th class="col-width">per day</th>
              </tr>

            </thead>
            <tbody>
              @for (price of hourlies; track price) {
                <tr>
                  <td>{{price.item}} <app-info-panel [price]="price"></app-info-panel></td>
                  <td>£{{price.cashflow}}</td>
                  <td>£{{price.dailyRate}}</td>
                </tr>
              }
            </tbody>
          </table>
        </div>

      </div>
      <div class="p-3 text-white">
        <i>All prices subject to V.A.T.</i>
      </div>
    </div>
  </div>




  <div class="container">
    <div class="p-3">
      <div class="card-deck">
        <div class="row w-100">
          <div class="col-sm-12 text-center w-100">
            <h2>Simple Website - example and comparison</h2>
            <p>Home Page, Privacy Page, Terms and Conditions Page</p>
            <p>Domain setup, Domain Hosting and no emails</p>
            <p>No updates</p>
          </div>

        </div>
      </div>

      <div class="row pb-3">
        <div class="bk-dark col-xs-12 col-sm-12 rounded z-depth-2">
          <table class="table table-striped table-dark">
            <thead>
              <tr>
                <th></th>
                <th>Traditional</th>
                <th>Cashflow Workflow</th>
              </tr>

            </thead>
            <tbody>
              <tr>
                <td>Build Cost</td>
                <td>£460</td>
                <td>Included</td>
              </tr>
              <tr>
                <td>Deposit</td>
                <td>£46</td>
                <td>£46</td>
              </tr>
              <tr>
                <td>Balance</td>
                <td>£414</td>
                <td>NIL</td>
              </tr>
              <tr>
                <td>Hosting Costs (Domain & Email) per month</td>
                <td>£15</td>
                <td>Included</td>
              </tr>
              <tr>
                <td>Cashflow monthly plan</td>
                <td>£n/a</td>
                <td>£61</td>
              </tr>
              <tr>
                <td>Updates each year</td>
                <td>£0</td>
                <td>Included</td>
              </tr>
              <tr>
                <td>Total Yearly Cost</td>
                <td>£640</td>
                <td>£717</td>
              </tr>
            </tbody>
          </table>
          <div class="quote text-center">
            For a small website with little to no updates it is cheaper to use the traditional payment pathway, although the Cashflow Workflow still spreads the payments with little upfront costs.
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="container">
    <div class="p-3">
      <div class="card-deck">
        <div class="row w-100">
          <div class="col-sm-12 text-center w-100">
            <h2>Typycal Medium Size Website - example and comparison</h2>
            <p>Home Page, Blog Page, Privacy Page, Terms and Conditions Page</p>
            <p>Domain setup, Domain Hosting and 3 emails with hosting</p>
            <p>12 Blogs and 5 updates</p>
          </div>

        </div>
      </div>

      <div class="row pb-3">
        <div class="bk-dark col-xs-12 col-sm-12 rounded z-depth-2">
          <table class="table table-striped table-dark">
            <thead>
              <tr>
                <th></th>
                <th>Traditional</th>
                <th>Cashflow Workflow</th>
              </tr>

            </thead>
            <tbody>
              <tr>
                <td>Build Cost</td>
                <td>£710</td>
                <td>Included</td>
              </tr>
              <tr>
                <td>Deposit</td>
                <td>£71</td>
                <td>£71</td>
              </tr>
              <tr>
                <td>Balance</td>
                <td>£639</td>
                <td>NIL</td>
              </tr>
              <tr>
                <td>Hosting Costs (Domain & Email) per month</td>
                <td>£30</td>
                <td>Included</td>
              </tr>
              <tr>
                <td>Cashflow monthly plan</td>
                <td>£n/a</td>
                <td>£101</td>
              </tr>
              <tr>
                <td>Blogs 1x per month</td>
                <td>£40</td>
                <td>Included</td>
              </tr>
              <tr>
                <td>Updates each year</td>
                <td>£200</td>
                <td>Included</td>
              </tr>
              <tr>
                <td>Total Yearly Cost</td>
                <td>£1,750</td>
                <td>£1,182</td>
              </tr>
            </tbody>
          </table>
          <div class="quote text-center">
            As well as no balance due on completion the Cashflow Workflow is over £500 cheaper in the first year, with free blog deployments and several updates included in the price.
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="container">
    <div class="p-3">
      <div class="card-deck">
        <div class="row w-100">
          <div class="col-sm-12 text-center w-100">
            <h2>Large Size Website - example and comparison</h2>
            <p>Home Page, Blog Page, About Us, Contact Page, Gallery, Privacy Page, Terms and Conditions Page</p>
            <p>Domain setup, Domain Hosting and 5 emails with hosting</p>
            <p>50 Blogs and 20 updates</p>
          </div>

        </div>
      </div>

      <div class="row pb-3">
        <div class="bk-dark col-xs-12 col-sm-12 rounded z-depth-2">
          <table class="table table-striped table-dark">
            <thead>
              <tr>
                <th></th>
                <th>Traditional</th>
                <th>Cashflow Workflow</th>
              </tr>

            </thead>
            <tbody>
              <tr>
                <td>Build Cost</td>
                <td>£1,160</td>
                <td>Included</td>
              </tr>
              <tr>
                <td>Deposit</td>
                <td>£116</td>
                <td>£116</td>
              </tr>
              <tr>
                <td>Balance</td>
                <td>£1,044</td>
                <td>NIL</td>
              </tr>
              <tr>
                <td>Hosting Costs (Domain & Email) per month</td>
                <td>£40</td>
                <td>Included</td>
              </tr>
              <tr>
                <td>Cashflow monthly plan</td>
                <td>£n/a</td>
                <td>£156</td>
              </tr>
              <tr>
                <td>Blogs 1x per week</td>
                <td>£2,000</td>
                <td>Included</td>
              </tr>
              <tr>
                <td>Updates each year</td>
                <td>£800</td>
                <td>Included</td>
              </tr>
              <tr>
                <td>Total Yearly Cost</td>
                <td>£3,640</td>
                <td>£1,832</td>
              </tr>
            </tbody>
          </table>
          <div class="quote text-center">
            On larger websites with many regular updates and plenty of blog deployments the Cashflow Workflow payment plan really comes into it's own with regular affordable payments and no nasty surprises
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
