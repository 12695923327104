import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BlogService } from 'src/app/services/blog.service';
import { TagLinkComponent } from '../tag-link/tag-link.component';


@Component({
    selector: 'app-tag-picker',
    templateUrl: './tag-picker.component.html',
    styleUrls: ['./tag-picker.component.scss'],
    standalone: true,
    imports: [TagLinkComponent]
})
export class TagPickerComponent implements OnInit {

  tags: string[];
  tagsPicked: string[] = [];

  @Output() tagPicked = new EventEmitter();

  constructor(private blogService: BlogService ) {
    this.tags = this.blogService.allTags;
   }

  ngOnInit(): void {
  }

  tagClicked(tag: string) {
    if (!this.tagsPicked.includes(tag)) {
      this.tagsPicked.push(tag);
    } else {
      this.tagsPicked = this.tagsPicked.filter(w => w !== tag);
    }

    this.tagPicked.emit(this.tagsPicked);
  }
}
