<div class="base-color p-5">
  <div class="container wow fadeInUp">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h2>Featured Blog Posts</h2>
        <p class="text-white">All the latest news from the world of web development and CWS</p>
      </div>
    </div>

    <div class="row">
      @for (post of posts; track post) {
        <div class="col-sm-4 wow flipInX" data-wow-delay="delay">
          <div class="mini-post p-3 mb-3">
            <app-blog-post [post]="post"></app-blog-post>
          </div>
        </div>
      }
    </div>
  </div>
</div>


