  <div class="container-fluid">

    <app-animation-intro></app-animation-intro>
    <app-intro></app-intro>

  </div>

  <div class="container-fluid">

    <app-banner-parallax>
      <app-differently-content></app-differently-content>
    </app-banner-parallax>

    <app-feature-cards [title]='"What ever you want, we&apos;ve got it"'
      [message]="'Tell us your ideas and we will make it happen, no job too small or too big!'" [features]="features1">
    </app-feature-cards>

  </div>

  <div class="container">
    <app-quote [imgsrc]="'charley-stagg.png'" [headline]="'SEEING A PROJECT THROUGH FROM START TO FINISH'"
      [name]="'Charley Stagg'" [title]="'Project Director'"
      [quote]="'&quot;seeing a project through from the clients initial ideas incorporating our collective expertise through to the finished product is what I love best about my job. I love to see ideas realised and built whilst retaining the customers personality within the final product to enable the client to build upon their own dreams of business&quot;'"
      [reverse]='true'></app-quote>
  </div>
  <div class="container-fluid">
    <app-banner-contact [title]="'Contact Charley'"
      [message]="'Call Charley now or use the contact form for a free no-obligation quotation. No job is too small or too big'"
      [btnType]="btnTypeEnum.email" [contact]="'charley@customwebsolutions.ltd'" [btnType2]="btnTypeEnum.tel"
      [contact2]="'07772 925024'"></app-banner-contact>
  </div>

  <div class="container-fluid">

    <app-blog-mini-view></app-blog-mini-view>

    <app-feature-cards [title]="'Other Services'"
      [message]='"just some of our other services, please contact us if you can&apos;t see what your looking for."'
      [features]="features2"></app-feature-cards>
  </div>
  <div class="container">
    <app-quote [imgsrc]="'andrew-stagg.png'" [headline]="'DOING IT DIFFERENTLY'" [name]="'Andrew Stagg'"
      [title]="'Managing Director'"
      [quote]="'&quot;Important to us is getting the user story and user experience right from the start, this is a key aspect for a succsessfull website or app project, therefore initial discussions, careful thought in the discovery phase and detailed planning are essential.&quot;<br><br>&quot;Just as important to you is your cashflow, so, let us take the strain and just pay for everything monthly with our Cashflow Workflow path, this gives you an extremely safe route to market and keeps your cashflow under control&quot;'">
    </app-quote>
  </div>
  <div class="container-fluid">
    <app-banner-contact [title]="'Contact Andrew'"
      [message]="'Please feel free to contact me at any time using any of the following links or the contact form below.'"
      [btnType]="btnTypeEnum.email" [contact]="'andrew@customwebsolutions.ltd'" [btnType2]="btnTypeEnum.tel"
      [contact2]="'07736 610970'"></app-banner-contact>
  </div>

  <div class="container">

    <app-tab></app-tab>

  </div>

  <div class="container-fluid" id="WhoAreWe">
    <app-opening-times></app-opening-times>

    <app-teams [title]="'WHO ARE WE?'" [message]="'A father and daughter team with many years of experience'"></app-teams>
  </div>

  <div class="container-fluid">
    <app-contact-form id="ContactForm"></app-contact-form>
  </div>
