import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-category-link',
    templateUrl: './category-link.component.html',
    styleUrls: ['./category-link.component.scss'],
    standalone: true,
    imports: [NgClass]
})
export class CategoryLinkComponent implements OnInit {

  isActive = false;
  
  @Input() cat: string;
  
  @Output() categoryPicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  categoryClicked(category: string) {
    this.categoryPicked.emit(category);
    this.isActive = !this.isActive;
      }

}
