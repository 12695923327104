import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-gdpr',
    templateUrl: './gdpr.component.html',
    styleUrls: ['./gdpr.component.scss'],
    standalone: true
})
export class GdprComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
