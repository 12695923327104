import { Component, Input, OnInit } from '@angular/core';
import { price } from 'src/app/models/pricing';


@Component({
    selector: 'app-info-panel',
    templateUrl: './info-panel.component.html',
    styleUrls: ['./info-panel.component.scss'],
    standalone: true,
    imports: []
})
export class InfoPanelComponent implements OnInit {

  @Input() price: price;

  showPanel = false;

  constructor() { }

  ngOnInit(): void {
  }

}
