@if (showPopup) {
  <div class="overlay">
    <span class="helper"></span>
    <div>
      <div (click)="BtnCancelClicked()" class="popupCloseButton">&times;</div>
      <h2>{{title}}</h2>
      <p>{{description}}</p>
      <div class="row">
        @if (btnShowAction) {
          <div class="col">
            <button mdbBtn color="warning" class="waves-effect" (click)="BtnActionClicked()">{{btnActionText}}</button>
          </div>
        }
        @if (btnShowCancel) {
          <div class="col">
            <button mdbBtn color="warning" outline="true" class="waves-effect" mdbWavesEffect (click)="BtnCancelClicked()">{{btnCancelText}}</button>
          </div>
        }
      </div>
    </div>
  </div>
}
