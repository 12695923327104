import { Component, OnInit, AfterViewInit, Input, ViewChild, ElementRef, HostListener } from '@angular/core';
import 'jarallax';
declare var jarallax: any;

@Component({
    selector: 'app-banner-parallax',
    templateUrl: './banner-parallax.component.html',
    styleUrls: ['./banner-parallax.component.scss'],
    standalone: true
})
export class BannerParallaxComponent implements OnInit, AfterViewInit  {

  @Input() bgImage: string;
  constructor() { }

  // @ViewChild('content', {read: ElementRef, static: false}) elementView: ElementRef;
  parallaxHeight: number;
  innerWidth: number;

  @HostListener('window:resize', ['$event'])

  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 544) {
      this.parallaxHeight = 700;
    } else if (this.innerWidth < 768) {
      this.parallaxHeight = 700;
    } else {
      this.parallaxHeight = 680;
    }
  }

  ngOnInit(): void {
    this.parallaxHeight = 600;
    if (this.bgImage === undefined || this.bgImage === '') {
      this.bgImage = 'cashflow_workflow.png';
    }
  }
  ngAfterViewInit() {
    jarallax(document.querySelectorAll('.jarallax'), {
      speed: 0.2
    });
  }

  Style() {
    return 'background-image: url(assets/images/' + this.bgImage + '); background-attachment: fixed; height:' + this.parallaxHeight + 'px;';
  }
}
