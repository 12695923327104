import { Component, OnInit, Input } from '@angular/core';
import { Post } from 'src/app/models/post';
import { RouterLink } from '@angular/router';


@Component({
    selector: 'app-blog-post',
    templateUrl: './blog-post.component.html',
    styleUrls: ['./blog-post.component.scss'],
    standalone: true,
    imports: [RouterLink]
})
export class BlogPostComponent implements OnInit {

  @Input() post: Post;


  constructor() {}

  ngOnInit(): void {}
}
