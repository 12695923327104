    <!--Navbar-->
    <nav class="navbar navbar-expand-lg navbar-dark base-color fixed-top">

      <div class="container">

        <a class="navbar-brand" href="#" [routerLink]="['/home']" class="logo-name align-middle">

          <img src="/assets/images/cws-logo.svg" class="cws-logo" alt="Custom Web Solutions Ltd">

          CUSTOM WEB SOLUTIONS</a>

          <button class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false" aria-label="Toggle navigation">
            <i class="fas fa-bars"></i>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <!-- Links -->
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">

          <li class="nav-item">
            <a class="nav-link" [routerLink]='["/home"]'>
              <i class="fas fa-home text-white"></i>&nbsp;Home
            </a>
          </li>

          <li class="nav-item active">
            <a class="nav-link waves-light cws-blue" [routerLink]='"."' [fragment]="'ContactForm'" mdbWavesEffect>
              <i class="fas fa-envelope text-white"></i>&nbsp;Contact
            </a>
          </li>
          <!--<li class="nav-item">
            <a class="nav-link waves-light cws-blue" [routerLink]='["/pricing"]' mdbWavesEffect>
              <i class="fas fa-pound-sign text-white"></i>&nbsp;Pricing
            </a>
          </li>-->

          <li class="nav-item">
            <a class="nav-link waves-light cws-blue" [routerLink]='"."' [fragment]="'WhoAreWe'" mdbWavesEffect>
              <i class="fas fa-users text-white"></i>&nbsp;Who are we?
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link waves-light cws-blue" [routerLink]="['/blog']"  mdbWavesEffect>
              <i class="fas fa-blog text-white"></i>&nbsp;Blog
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link waves-light cws-blue" [routerLink]="['/faq']"  mdbWavesEffect>
              <i class="fas fa-question-circle text-white"></i>&nbsp;FAQ
            </a>
          </li>


        </ul>
        <!-- Links -->

      </div>
      <!-- Collapsible content -->

      </div>
    </nav>
    <!--/.Navbar-->
