<span class="tag-title">Tags@if (tagsPicked.length > 0) {
  <span  class="picked"> - includes@for (picked of tagsPicked; track picked) {
    <span> - {{picked}} </span>
  }</span>
}</span>
<ul class="nav nav-fill justify-content-center">
  @for (tag of tags; track tag) {
    <li class="nav-item cws-blue p-3 text-uppercase">
      <app-tag-link [tag]="tag" (tagPicked)="tagClicked(tag)"></app-tag-link>
    </li>
  }
</ul>
