import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-timeline-workflow',
    templateUrl: './timeline-workflow.component.html',
    styleUrls: ['./timeline-workflow.component.scss'],
    standalone: true
})
export class TimelineWorkflowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
