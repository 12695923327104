import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
    selector: 'app-quote',
    templateUrl: './quote.component.html',
    styleUrls: ['./quote.component.scss'],
    standalone: true
})
export class QuoteComponent implements OnInit {

  @Input()
  imgsrc: string;
  safeSrc: string;

  @Input()
  paddingCSSclass: string;

  @Input()
  headline: string;

  @Input()
  quote: string;

  @Input()
  name: string;

  @Input()
  title: string;

  @Input()
  reverse: boolean;

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.safeSrc = './assets/images/' + this.imgsrc;
  }

  getAltText() {
    return this.name + ' ' + this.title;
  }

  getTitleText() {
    return this.name + ' ' + this.title;
  }

  getReverseClass(): string {
    return this.reverse ? 'd-flex flex-row-reverse' : 'd-flex row';
  }
}
