import { Component, OnInit } from '@angular/core';
import { btnTypeEnum } from 'src/app/enums/enums';
import { DataService } from 'src/app/services/data.service';
import { IFeature } from 'src/app/models/IFeature';
import { Title, Meta } from '@angular/platform-browser';
import { ContactFormComponent } from '../../components/forms/contact-form/contact-form.component';
import { TeamsComponent } from '../../components/features/teams/teams.component';
import { OpeningTimesComponent } from '../../components/opening-times/opening-times.component';
import { TabComponent } from '../../components/tabs/tab/tab.component';
import { BlogMiniViewComponent } from '../../components/blogs/blog-mini-view/blog-mini-view.component';
import { ContactComponent } from '../../components/banners/contact/contact.component';
import { QuoteComponent } from '../../components/articles/quote/quote.component';
import { FeatureCardsComponent } from '../../components/features/feature-cards/feature-cards.component';
import { DifferentlyContentComponent } from '../../components/content/differently-content/differently-content.component';
import { BannerParallaxComponent } from '../../components/banners/banner-parallax/banner-parallax.component';
import { IntroComponent } from '../../components/intros/intro/intro.component';
import { AnimationIntroComponent } from '../../components/intros/animation-intro/animation-intro.component';


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: true,
    imports: [AnimationIntroComponent, IntroComponent, BannerParallaxComponent, DifferentlyContentComponent, FeatureCardsComponent, QuoteComponent, ContactComponent, BlogMiniViewComponent, TabComponent, OpeningTimesComponent, TeamsComponent, ContactFormComponent]
})
export class HomeComponent implements OnInit {
  features1: IFeature[] = [];
  features2: IFeature[] = [];

  btnTypeEnum = btnTypeEnum;

  constructor(private dataservice: DataService, private titleService: Title, private metaTagService: Meta) {
    dataservice.GetFeatures().subscribe((data) => {
      this.features1 = data.filter(w => w.set === 1);
      this.features2 = data.filter(w => w.set === 2);
    },
    (err) => {
      console.log(err);
    });
    
   }
  ngOnInit(): void {
    this.metaTagService.updateTag(
      { name: 'description', content: 'Beautifully designed functional websites and mobile apps made to your specifications. Pay monthly for all services, call now for a free no obligation quote'}
    );  
    this.metaTagService.updateTag(
      { name: 'title', content: 'Custom Web Design | Pay Monthly | CWS Ltd'}
    );
    
  }
}
