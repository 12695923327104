<div class="card wow flipInX text-center" data-wow-delay="delay">
  <!--Card image-->
  <!-- <mdb-card-img [src]="src" [class]="paddingCSSclass"></mdb-card-img> -->
  <div class="card-img-top">
    <div class="row pt-4">
      <div class="col-sm-4 offset-sm-4">
        <img class="img-fluid" [src]="src" alt="{{cardTitle}}" title="{{cardTitle}}">
      </div>
    </div>
  </div>
  <!--Card content-->
  <div class="card-body">

    <!--Title-->
    <h4 class="card-title">{{cardTitle}}</h4>

    <!--Text-->
    <div class="card-text pb-4">{{cardText}}</div>

    <div class="text-center">
      <h2>{{name}}</h2>
      <p>{{position}}</p>
    </div>
    <div>
      @if (showButton) {
        <a (click)="onCardButtonClicked()" class="btn btn-color-dark" >{{buttonText}}</a>
      }
    </div>
  </div>
</div>
