import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { FooterComponent } from './components/footer/footer.component';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [HeaderComponent, RouterOutlet, FooterComponent]
})
export class AppComponent implements OnInit{

  constructor(
    private metaTagService: Meta
  ) {}
  
  ngOnInit() {
    this.metaTagService.addTags([
      {name: 'keywords', content: 'website design, web design, web design company, hampshire web design, responsive web development'},
      {name: 'robots', content: 'index, follow'},
      {name: 'author', content: 'CWS'},
      {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      {charset: 'UTF-8'}
    ])
  }
}
