
@if (price.info.length > 0) {
  <i class="infoIcon text-primary fas fa-info-circle fa-lg" (click)="showPanel = !showPanel"></i>
}

@if (showPanel) {
  <div (click)="showPanel=false">
    <div class="overlay">
    </div>
    <div class="panel">
      <h3>{{price.item}}</h3>
      <div class="row text-center">
        <div class="col-6">
          <i class="fas fa-balance-scale text-danger fa-3x"></i><br>
          Traditional<br>
          £{{price.traditional}} {{price.hourlyOnly || price.monthlyOnly ? price.hourlyOnly  ? "(hourly)" : "(monthly)" : ""}}
        </div>
        <div class="col-6">
          <i class="fas fa-chart-line text-success fa-3x"></i><br>
          Cashflow Workflow<br>
          £{{price.cashflow}} ({{price.hourlyOnly ? "hourly" : "monthly"}})
        </div>
      </div>
      <br>
        <p>
          {{price.info}}
        </p>
      </div>
    </div>
  }
