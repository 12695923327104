<div class="container">
  <div class="p-5">
    <div class="row">
      <div class="col-12 text-center transparent size6 p-3 h-100">
        TRANSPARENT PRICING
      </div>
    </div>
    <br>
    <div class="card-deck">
      <div class="row">
        <div class="col-12 col-md-6 text-center">

          <div class="card">
          <div class="card-img p-3">
            <i class="fas fa-balance-scale fa-5x text-danger"></i>
          </div>
          <div class="card-box align-center p-2">
            <h4 class="card-title display-7">BUY THE TRADITIONAL WAY</h4>
            <p class="display-7">Just pay a 10% desposit and pay the rest when your site is up and running. Hosting fees, SEO work
              and updates will charged extra. POA.</p>

          </div>
        </div>
        </div>

        <div class="col-12 col-md-6 text-center">
          <div class="card">

          <div class="card-img p-3">
            <i class="fas fa-chart-line text-success fa-5x"></i>
          </div>
          <div class="card-box align-center p-2">
            <h4 class="card-title display-7">OR OUR CASHFLOW WORKFLOW</h4>
            <p class="display-7">Pay the same deposit as traditional way but then just pay a monthly maintenace fee
              which includes all hosting and updates all of the time you stay with us. SEO work can be also included.</p>
          </div>
        </div>
      </div>
    </div>
    </div>
    <br>
    <div class="row">
      <div class="col-12 text-center transparent size3 p-3">
        choose what's best for you
      </div>
    </div>
  </div>
</div>
