import { Component, OnInit } from '@angular/core';
import { IContact, Contact } from '../../../models/IContact';
import { Validators, UntypedFormGroup, UntypedFormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { ModalPopupComponent } from '../../popups/modal-popup/modal-popup.component';

import { MatCheckbox } from '@angular/material/checkbox';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';

@Component({
    selector: 'app-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormField, MatInput, MatLabel, MatCheckbox, ModalPopupComponent]
})
export class ContactFormComponent implements OnInit {

  contact: IContact;
  contactSent = false;
  contactFailed = false;
  contactForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private dataService: DataService) {

    this.contactForm = this.createFormGroup();
   }

  ngOnInit(): void {
    this.contact = new Contact();
  }

  createFormGroup() {
    return this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      desc: ['', [Validators.required]],
      client: ['J2hwEiwhc', [Validators.required]]
    });
  }

  formSetValue() {
    if (this.contact !== undefined) {
      this.contactForm.reset({
        name: '',
        email: '',
        phone: '',
        desc: '',
        client: 'J2hwEiwhc'
      });
    }
  }

  SubmitForm() {
    // this.contactSent = true;

    if (this.contactForm.valid) {
      this.contact = Object.assign({}, this.contactForm.value);

      this.dataService.SendContactForm(this.contact)
        .subscribe(
          response => {
            this.contactSent = true;
          },
          error => {
            this.contactFailed = true;
            console.error('Error: ' + error.response);
          });
    }
  }
  cancelError() {
    this.contactFailed = false;
  }
  cancelClicked() {
    this.resetNewItemToOriginal();
  }

  resetNewItemToOriginal() {
    // set the temp to the orginal
    this.formSetValue();
    this.contactSent = false;
    this.contactFailed = false;
  }
}

