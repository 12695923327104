import { Component, OnInit } from '@angular/core';
import { price } from 'src/app/models/pricing';
import { DataService } from 'src/app/services/data.service';
import { Title, Meta } from '@angular/platform-browser';
import { InfoPanelComponent } from '../../components/info-panel/info-panel.component';

import { TimelineWorkflowComponent } from '../../components/timelines/timeline-workflow/timeline-workflow.component';
import { PayoptionsContentComponent } from '../../components/content/payoptions-content/payoptions-content.component';
import { BannerParallaxComponent } from '../../components/banners/banner-parallax/banner-parallax.component';

@Component({
    selector: 'app-pricing',
    templateUrl: './pricing.component.html',
    styleUrls: ['./pricing.component.scss'],
    standalone: true,
    imports: [BannerParallaxComponent, PayoptionsContentComponent, TimelineWorkflowComponent, InfoPanelComponent]
})
export class PricingComponent implements OnInit {

  prices: price[];
  monthlies: price[];
  hourlies: price[];

  constructor(private dataService: DataService, private metaTagService: Meta) {
    dataService.GetPricing().subscribe((data) => {
      this.prices = data.filter(w => !w.monthlyOnly && !w.hourlyOnly);
      this.monthlies = data.filter(w => w.monthlyOnly);
      this.hourlies = data.filter(w => w.hourlyOnly);
    });
  }

  ngOnInit(): void {
    this.metaTagService.updateTag(
      {name: 'keywords', content: 'how much does a website cost UK, pay monthly website, responsive website design, mobile web development'}
      );
    this.metaTagService.updateTag(
      {name: 'title', content: 'Pricing Structure | Pay Monthly | CWS Ltd'}
      );
  }

}
