<div class="container">
  <!-- Type valid HTML here -->
  <div class="row">
    <div class="col">

      <h1>Custom Web Solutions Ltd</h1>
      <h2>Sitemap</h2>
      <div class="panel-sitemap">
        <ul>
          <li>
            <a href="https://customwebsolutions.ltd/index.html">Home</a><br>
          </li>
          <li>
            <a href="https://customwebsolutions.ltd/pricing">Pricing</a>
          </li>
          <li>
            <a href="https://customwebsolutions.ltd/seo">SEO</a>
          </li>
          <li>
            <a href="https://customwebsolutions.ltd/blog">Blog</a>
          </li>
          <li>
            <a href="https://customwebsolutions.ltd/faq">FAQ</a>
          </li>
          <li>
            <a href="https://customwebsolutions.ltd/privacy">Privacy</a>
          </li>

          <li>
            <a href="https://customwebsolutions.ltd/terms">Terms</a>
          </li>

        </ul>
      </div>

    </div>
  </div>
</div>
