import { Component, OnInit } from '@angular/core';
import { Post } from 'src/app/models/post';
import { BlogService } from 'src/app/services/blog.service';
import { BlogPostComponent } from '../blog-post/blog-post.component';


@Component({
    selector: 'app-blog-mini-view',
    templateUrl: './blog-mini-view.component.html',
    styleUrls: ['./blog-mini-view.component.scss'],
    standalone: true,
    imports: [BlogPostComponent]
})
export class BlogMiniViewComponent implements OnInit {

  posts: Post[];

  constructor(private blogService: BlogService) {
    this.posts = blogService.GetPosts([], undefined, 3, 0);
  }

  ngOnInit(): void {
  }

}
