import { Component, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { IFeature } from 'src/app/models/IFeature';
import { FeatureCardImageComponent } from '../feature-card-image/feature-card-image.component';
import { FeatureCardComponent } from '../feature-card/feature-card.component';


@Component({
    selector: 'app-feature-cards',
    templateUrl: './feature-cards.component.html',
    styleUrls: ['./feature-cards.component.scss'],
    standalone: true,
    imports: [FeatureCardComponent, FeatureCardImageComponent]
})
export class FeatureCardsComponent implements OnInit {

  @Input() title: string;

  @Input() message: string;

  @Input() features: IFeature[] = [];

  constructor() {}

  ngOnInit(): void {
  }

}
