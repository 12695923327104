import { Component, OnInit } from '@angular/core';
import { MatTabGroup, MatTab } from '@angular/material/tabs';

@Component({
    selector: 'app-tab',
    templateUrl: './tab.component.html',
    styleUrls: ['./tab.component.scss'],
    standalone: true,
    imports: [MatTabGroup, MatTab]
})
export class TabComponent implements OnInit {

  safeImgURL: string; // = './assets/images/serverless.jpg';
  lastImg: string;
  selectedIndex = 0;
  images: string[] = ['serverless.jpg', 'aws_services.jpg' , 'ms_azure.jpg'];

  constructor() {
   }

  ngOnInit(): void {
    this.image();
  }

  image(): void {
     this.lastImg = './assets/images/' + this.images[this.selectedIndex];
     if (this.safeImgURL !== this.lastImg) {
      this.safeImgURL = this.lastImg;
    }
  }
}
