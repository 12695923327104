import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-sitemap',
    templateUrl: './sitemap.component.html',
    styleUrls: ['./sitemap.component.scss'],
    standalone: true
})
export class SitemapComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
