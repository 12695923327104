import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { FAQ } from 'src/app/models/faq';
import { FilterSearchFieldsPipe } from '../../filters';
import { FaqItemComponent } from './faq-item/faq-item.component';

import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss'],
    standalone: true,
    imports: [FormsModule, FaqItemComponent, FilterSearchFieldsPipe]
})
export class FaqComponent implements OnInit {

  faqs: FAQ[];

  search: string;

  constructor(private dataService: DataService) {
    dataService.GetFAQs().subscribe((data) => {
      this.faqs = data;
    });

   }

  ngOnInit(): void {
  }

}
