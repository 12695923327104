import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BlogService } from 'src/app/services/blog.service';
import { CategoryLinkComponent } from '../category-link/category-link.component';


@Component({
    selector: 'app-category-picker',
    templateUrl: './category-picker.component.html',
    styleUrls: ['./category-picker.component.scss'],
    standalone: true,
    imports: [CategoryLinkComponent]
})
export class CategoryPickerComponent implements OnInit {

  categories: string[] = [];
  categoriesPicked: string[] = [];

  @Output() categoryPicked = new EventEmitter();

  constructor(private blogService: BlogService) {
    this.categories = blogService.allCats;
  }

  ngOnInit(): void {
  }

  categoryClicked(category: string) {

    if (!this.categoriesPicked.includes(category)) {
      this.categoriesPicked.push(category);
    } else {
      this.categoriesPicked = this.categoriesPicked.filter(w => w !== category);
    }

    this.categoryPicked.emit(this.categoriesPicked);
      }
}
