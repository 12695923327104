<div class="row">

<div class="col">
  <div class="m-5">

    <div class="text-center">
      <h1 class="display-2">Custom Website Design and App Development</h1>
      <!--<h3 class="display-5">Beautifully designed functional websites and mobile apps made to your specifications.<br></h3>-->
        <h2><a [routerLink]='"."' [fragment]="'CashflowWorkflow'">Cashflow Workflow - Pay monthly for all services</a></h2>

          <!--<div class="rounded mx-auto d-block">
            <img class="img-fluid" src="/assets/images/coding-2.jpg" alt="In house development of mobile apps and websites" title="In house development of mobile apps and websites">
          </div>-->
    </div>

  </div>
</div>

</div>


