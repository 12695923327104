import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-differently-content',
    templateUrl: './differently-content.component.html',
    styleUrls: ['./differently-content.component.scss'],
    standalone: true,
    imports: [RouterLink]
})
export class DifferentlyContentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
