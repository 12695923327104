<div class="backgroundMed">

  <div class="container pt-3">
    <div class="row justify-content-center text-white">
      <div class="media-container-column col-lg-8 text-center">
        <div class="ml-3 pl-3">
          <h2 class="align-center pb-3 display-2">Drop us a line</h2>
          <h3 class="align-center mbr-light pb-3  display-5">Let's get the ball rolling, give us a brief outline of what you're after and let us help you get your idea up and running.</h3>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row justify-content-center">
      <div class="media-container-column col-lg-8 ">

        <form [formGroup]="contactForm" (ngSubmit)="SubmitForm()">
          <div class="backgroundLight p-3 m-3">
            <div class="row row-sm-offset">
              <div class="col-md-4 multi-horizontal">
                <mat-form-field class="mt-0 mb-0" appearance="fill">
                  <input type="text" class="p-1" matInput name="name" id="forName" formControlName="name">
                  <mat-label for="forName" title="your full name would be nice">name</mat-label>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field class="mt-0 mb-0" appearance="fill">
                  <input type="email"  class="p-1" matInput name="email" id="formEmail" formControlName="email">
                  <mat-label for="formEmail" title="enter your email address">email address</mat-label>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field class="mt-0 mb-0" appearance="fill">
                  <input type="tel" class="p-1" matInput name="phone" id="forPhone" formControlName="phone">
                  <mat-label for="forPhone" title="It's good to talk">telephone number</mat-label>
                </mat-form-field>
              </div>
            </div>
            <div class="row my-4">
              <div class="col">
                <mat-label aria-label="Services required" class="my-auto">     </mat-label>
                <mat-checkbox class="py-2 pr-2" value="1">Web Design and Development</mat-checkbox>
                <mat-checkbox class="py-2 px-2" value="2">SEO</mat-checkbox>
                <mat-checkbox class="py-2 px-2" value="3">E-Commerce</mat-checkbox>
                <mat-checkbox class="py-2 px-2" value="4">Redesign of current site</mat-checkbox>
              </div>
            </div>
            <div class="row row-sm-offset my-4">
              <div class="col-md-4 my-auto ml-1">
                <mat-checkbox #hasWebsite>
                  Do you currently have a website?
                </mat-checkbox>
              </div>


            </div>
            <div class="row row-sm-offset">
              @if (hasWebsite.checked) {
                <div class="col-12">
                  <mat-form-field class="mt-0 mb-0 w-100" appearance="fill">
                    <input type="text" matInput name="url" id="forUrl" formControlName="url">
                    <mat-label for="forUrl" title="current URL if applicable">current URL</mat-label>
                  </mat-form-field>
                </div>
              }
            </div>

          </div>

          <div class="backgroundLight p-3 m-3">
            <div class="row row-sm-offset">
              <div class="col-sm-12">
                <mat-form-field class="mt-0 mb-0 w-100" appearance="fill">
                  <textarea type="text" class="md-textarea form-control" matInput rows="7" id="forDesc" formControlName="desc"></textarea>
                  <mat-label for="forDesc" title="As much information as you can would be good">Please give a brief outline of your requirements</mat-label>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="m-2 ml-3 mb-5">
            <span class="input-group-btn"><a type="submit" (click)="SubmitForm()" class="btn btn-color-dark btn-form">Send</a></span>
          </div>

        </form>
      </div>
    </div>
  </div>

</div>

<app-modal-popup [showPopup]="contactSent" [title]="'Contact Form Sent'" [description]="'Thank you - We will be in touch shortly'" [btnCancelText]="'Cancel'" (cancelClicked)="cancelClicked()"></app-modal-popup>
<app-modal-popup [showPopup]="contactFailed" [title]="'Error sending email form'" [description]="'Please try again, if the problem persists please send your enquiry directly to charley@customwebsolutions.ltd'" [btnCancelText]="'Cancel'" (cancelClicked)="cancelError()"></app-modal-popup>

