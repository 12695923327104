<div class="navbar-dark base-color p-5">
  <div class="container wow fadeInUp">

    <div class="text-center text-white">
      <h2>{{title}}</h2>
      <p>{{message}}</p>
    </div>
    <div class="row pt-3">

      @for (feature of features; track feature) {
        <div class="col-sm-12 col-md-4 text-center card-group p-3">
          @if (feature.iconCss !== null) {
            <app-feature-card [iconCss]="feature.iconCss" [title]="feature.title" [bodytext]="feature.bodytext" [delay]="feature.id"></app-feature-card>
          }
          @if (feature.imgSrc !== null) {
            <app-feature-card-image [imgsrc]="feature.imgSrc" [title]="feature.title" [bodytext]="feature.bodytext" [delay]="feature.id"></app-feature-card-image>
          }
        </div>
      }

    </div>
  </div>
</div>
