<div class="container align-center p-5">
  <h2 class="cws-section-title">Cashflow Workflow<br><br></h2>
  <h3 class="cws-section-subtitle"><b>Unique Pricing Structure</b><br>Here's how it works&nbsp;</h3>

  <div class="container timelines-container" cwsi-timelines="">

      <!--1-->
      <div class="row timeline-element reverse separline">
          <span class="iconsBackground align-self-center">
            <div class="cws-iconfont fa-fw wow rotateIn" data-wow-delay="0.6s">
              <i class="far fa-question-circle"></i>
            </div>
          </span>
          <div class="col-xs-12 col-md-6 align-left wow slideInLeft">
              <div class="timeline-text-content">
                  <h4 class="cws-timeline-title pb-3 cws-fonts-style display-5">Discovery</h4>
                  <p class="cws-timeline-text cws-fonts-style display-7">Together we gather all of your requirements needed to build the companies vision, define all of the goals, aspirations and expectations needed and take stock of existing imagery and content. &nbsp;<br><br>YOU PAY NOTHING YET!</p>
               </div>
          </div>
      </div>

      <!--2-->
      <div class="row timeline-element  separline">
          <span class="iconsBackground">
            <div class="cws-iconfont fa-fw wow rotateIn" data-wow-delay="0.6s">
            <i class="fas fa-pencil-alt"></i>
            </div>
          </span>
          <div class="col-xs-12 col-md-6 align-left wow slideInRight">
              <div class="timeline-text-content">
                  <h4 class="cws-timeline-title pb-3 cws-fonts-style display-5">Design and Planning</h4>
                  <p class="cws-timeline-text cws-fonts-style display-7">We take away the ideas and bring it to life with mockups and/or wireframes for you to approve and develop and refine.<br><br>YOU PAY NOTHING YET!<br>
                  </p>
              </div>
          </div>
      </div>
      <!--3-->
      <div class="row timeline-element reverse separline">
          <span class="iconsBackground">
            <div class="cws-iconfont fa-fw wow rotateIn" data-wow-delay="0.6s">
            <i class="fas fa-laptop-code"></i>
            </div>
          </span>
          <div class="col-xs-12 col-md-6 align-left wow slideInLeft">
              <div class="timeline-text-content">
                  <h4 class="cws-timeline-title pb-3 cws-fonts-style display-5">Build</h4>
                  <p class="cws-timeline-text cws-fonts-style display-7">We beaver away for a few weeks to expertly build and develop the masterpiece using the latest technologies.<br><br>PAY A SMALL DEPOSIT (Just to keep the hamsters fed)
                  </p>
              </div>
          </div>
      </div>
      <!--4-->
      <div class="row timeline-element  separline">
          <span class="iconsBackground">
            <div class="cws-iconfont fa-fw wow rotateIn" data-wow-delay="0.6s">
              <i class="fas fa-cloud-upload-alt"></i>
            </div>
            </span>
          <div class="col-xs-12 col-md-6 align-left wow slideInRight">
              <div class="timeline-text-content">
                  <h4 class="cws-timeline-title pb-3 cws-fonts-style display-5">Deploy</h4>
                  <p class="cws-timeline-text cws-fonts-style display-7">Once you're happy we'll unleash our creation to the rest of the world.<br><br>PAY NOTHING! (Well not yet, see maintenance phase)<br>
                  </p>
              </div>
          </div>
      </div>
      <!--5-->
      <div class="row timeline-element reverse">
          <span class="iconsBackground">
            <div class="cws-iconfont fa-fw wow rotateIn" data-wow-delay="0.6s">
              <i class="fas fa-tools"></i>
            </div>
          </span>
          <div class="col-xs-12 col-md-6 align-left wow slideInLeft">
              <div class="timeline-text-content">
                  <h4 class="cws-timeline-title pb-3 cws-fonts-style display-5">Maintenance</h4>
                  <p class="cws-timeline-text cws-fonts-style display-7">As well as building your Website/App We'll:-<br>Host it, back it up and keep it secure<br>Host and manage your Emails (if req.)<br>Make regular updates<br>Maintain Databases and Servers (where req.)<br>Update Security Patches<br>Keep it running with 99.99% uptime.<br><br>JUST PAY ONE SIMPLE MONTHLY PAYMENT<br></p>
              </div>
          </div>
      </div>

  </div>
</div>
