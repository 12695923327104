import { Component, OnInit } from '@angular/core';
import { BlogService } from 'src/app/services/blog.service';
import { Post } from 'src/app/models/post';
import { ActivatedRoute } from '@angular/router';
import { Meta } from '@angular/platform-browser';


@Component({
    selector: 'app-blog-full-post',
    templateUrl: './blog-full-post.component.html',
    styleUrls: ['./blog-full-post.component.scss'],
    standalone: true,
    imports: []
})
export class BlogFullPostComponent implements OnInit {

  blogId: string;
  html: string;
  post: Post;

  constructor(
    private blogService: BlogService,
    private route: ActivatedRoute,
    private metaTagService: Meta) {
   }

  ngOnInit(): void {
    this.route.params.subscribe(routeParams => {
      this.blogId = (routeParams.blogId);
      this.post = this.blogService.GetPostByID(this.blogId);

      this.metaTagService.updateTag(
        {name: 'keywords', content: this.post.metaKeywords}
        );
      this.metaTagService.updateTag(
        {name: 'title', content: this.post.metaTitle}
        );
      this.metaTagService.updateTag(
        {name: 'description', content: this.post.metaDescription}
        );


      this.blogService.GetPost(this.post.postHtml).subscribe((postHtml) => {
        this.html = postHtml;
      },
      (err) => {
        console.log(err);
      });
    });
  }

}
